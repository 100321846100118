import './App.css';


function App() {


  return (
    <div className="App">
      <header className="App-header">
      <div className="container">
        <h1>Oaktechnic website will be available soon !!!</h1>
        <button onClick={() =>alert('Oaktechnic will be available soon')}  className="btn btn-primary">Soon We are here</button>
      </div>
        
      </header>
    </div>
  );
}

export default App;
